<template>
  <div>
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="value"
        :options="options"
        @change="onChange"
      />
    </van-dropdown-menu>
    <van-cell-group>
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        is-link
        :title="item.inventoryOrderCode"
        @click="toDetail(item)"
      >
        <template #label>
          <div>{{ item.nodeName }}</div>
          <div>{{ item.inventoryTime }}</div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      value: 0,
      options: [
        { value: 0, text: this.$t('inventoryTypes[0]') },
        { value: 1, text: this.$t('inventoryTypes[1]') },
        { value: 2, text: this.$t('inventoryTypes[2]') },
      ],
    }
  },
  created() {
    this.listAppInventoryOrder(this.value)
  },
  methods: {
    async listAppInventoryOrder(inventoryType) {
      const { data } = await this.$api.listAppInventoryOrder({ inventoryType })
      console.log('listAppInventoryOrder===>', data)
      this.list = data
    },
    onChange(val) {
      // console.log(val)
      this.listAppInventoryOrder(val)
    },
    toDetail(row) {
      this.$router.push({ name: 'InventoryDetail', params: row })
    },
  },
}
</script>